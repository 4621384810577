#home-hero-section {
    background-image: url('https://presentationpoint.com/wp-content/uploads/2021/10/what-is-a-data-dashboard.jpg');
    background-size: cover;
}

.home-hero-content {
    display: flex;
    flex-direction: column;
    color: white;
}

.home-hero-btns {
    display: flex;
}

#home-services-content {
    display: flex;
    margin: 0 auto;
    align-items: center;
    width: 80%;
}

.home-services-image img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

#home-services-overview {
    display: flex;
    flex-direction: column;
    width: 50%;
    border-left: 3px solid white;
}

.home-services-text {
    display: flex;
    flex-direction: column;
    transition: border-left 0.05s;
}

.services-overview-selected {
    border-left: 3px solid #eeba2c;
}

#home-solutions-section {
    background-color: #f5f5f5;
}

#home-solutions-content {
    display: flex;
    width: 90vw;
    margin: 0 auto;
    align-items: center;
}

.home-solutions-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 2vw 0 0;
}

#home-solutions-perks {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.home-solutions-perk {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 45%;
}

#home-solutions-btns {
    display: flex;
    gap: 1vw;
}

.home-solutions-image {
    padding: 0 0 0 2vw;
    width: 50%;
    height: 100%;
}

.home-solutions-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

#home-contact-information  {
    width: 50%;
    display: flex;
    flex-direction: column;
}

#home-contact-information button {
    width: 30%;

}

@media only screen and (min-width: 768px) and (max-width: 1920px) {
    .home-hero-content {
        width: 40%;
        height: 100%;
        justify-content: center;
        text-align: left;
        margin-left: 10vw;
        gap: 1vh;
    }

    .home-hero-content h1 {
        font-size: clamp(40px, 3.125vw, 60px);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    .home-hero-content p {
        font-size: clamp(20px, 1.5625vw, 30px);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    .home-hero-btns {
        gap: 1vw;
    }

    #home-services-section {
        height: 100vh;
    }
    
    #home-services-content {
        height: 100%;
        gap: 4vw;
    }
    
    .home-services-image {
        width: 50%;
        height: 60%;
    }

    #home-services-overview {
        gap: 6vh;
    }

    .home-services-text {
        gap: 2vh;
        padding: 0 0 0 2vw;
    }

    .home-services-text h2 {
        font-size: clamp(30px, 2.34375vw, 40px);
    }

    .home-services-text p {
        font-size: clamp(17px, 1.328125vw, 20px);
        line-height: calc(1.328125vw*1.3);
    }

    #home-solutions-section {
        height: 90vh;
        padding: 10vh 0 0 0;
    }

    #home-solutions-content {
        height: 80%;
    }

    #home-solutions-heading h2{
        font-size: clamp(40px, 3.125vw, 55px);
        margin-bottom: 2vh;
    }

    #home-solutions-heading p {
        font-size: clamp(17px, 1.328125vw, 20px);
        line-height: calc(1.328125vw*1.3);
    }

    .home-solutions-perk h3 {
        font-size: clamp(25px, 1.953125vw, 30px);
        margin: 0 auto 0 0;
    }

    .home-solutions-perk p {
        font-size: clamp(17px, 1.328125vw, 20px);
        line-height: calc(1.328125vw*1.3);
    }

    .home-solutions-text {
        justify-content: space-between;
        height: 100%;
    }

    #home-contact-content {
        width: 90vw;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        height: 40vh;
        padding: 10vh 0;
    }
    
    #home-contact-content h2 {
        font-size: clamp(40px, 3.125vw, 60px);
        width: 50%;
        padding: 0 4vw;
    }

    #home-contact-information {
        gap: 2vh;
    }

    #home-contact-information p {
        font-size: clamp(17px, 1.328125vw, 25px);
        line-height: calc(1.328125vw*1.3);
    }

    #home-contact-information a {
        width: fit-content;
    }

}

@media only screen and (min-width: 260px) and (max-width: 428px) {
    #home-hero-section {
        background-position: -350px;
    }
    .home-hero-content {
        width: 90%;
        margin: 0 auto;
        gap: var(--1vh);
        justify-content: center;
        height: 100%;
    }

    .home-hero-content h1 {
        font-size: clamp(30px, 9.1667vw, 40px);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    .home-hero-content p {
        font-size: clamp(20px, 5.55vw, 30px);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    .home-hero-btns {
        gap: 5%;
    }

    #home-services-section {
        height: var(--100vh);
    }

    #home-services-content {
        flex-flow: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 5%;
        width: 90%;
        height: 100%;
    }

    .home-services-image {
        width: 100%;
        height: 30%;
    }

    .home-services-image img {
        max-height: 100%;
    }

    #home-services-overview {
        width: 100%;
        gap: 20px;
    }

    .home-services-text {
        width: 100%;
        gap: 10px;
        padding: 0;
    }

    .home-services-text h2 {
        font-size: clamp(20px, 6.1111vw, 30px);
    }

    .home-services-text p {
        font-size: clamp(15px, 4.16vw, 20px);
        line-height: calc(4.16vw*1.3);
    }

    #home-solutions-section {
        height: calc(var(--1vh)*120);
    }

    #home-solutions-content {
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: 5%;
        width: 90%;
        height: 100%;
    }

    .home-solutions-text {
        width: 100%;
        gap: 10px;
        padding: 0;
        justify-content: center;
    }

    #home-solutions-heading h2 {
        font-size: clamp(20px, 6.1111vw, 30px);
    }

    #home-solutions-heading p {
        font-size: clamp(15px, 4.16vw, 20px);
        line-height: calc(4.16vw*1.3);
    }

    .home-solutions-perk h3 {
        font-size: clamp(17px, 4.7222vw, 22px);
    }

    .home-solutions-perk p {
        font-size: clamp(15px, 4.16vw, 20px);
        line-height: calc(4.16vw*1.3);
    }

    .home-solutions-image {
        width: 100%;
        height: 30%;
    }

    #home-solutions-btns {
        justify-content: space-around;
    }

    #home-contact-content {
        height: calc(var(--1vh)*60);
        padding: 10vh 4vw;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: calc(var(--1vh)*2);
    }

    #home-contact-content h2 {
        font-size: clamp(25px, 6.9444vw, 30px);
    }

    #home-contact-information {
        width: 100%;
        gap: 10px;
    }

    #home-contact-information p {
        font-size: clamp(15px, 4.16vw, 20px);
        line-height: calc(4.16vw*1.3);
    }

    #home-contact-information a {
        width: fit-content;
    }

}