@media only screen and (min-width: 768px) and (max-width: 1920px) {
    #contact-hero-section {
        padding: 10vh 0 0 0;
        height: 50vh;
        background-image: url('https://www.theladders.com/wp-content/uploads/handshake_190617.jpg');
        background-size: cover;
        background-position: center;
    }

    .contact-hero-content {
        display: flex;
        flex-direction: column;
        padding: 0 0 0 10vw;
        justify-content: center;
        color: white;
        height: 100%;
    }

    .contact-hero-content h1 {
        font-size: clamp(45px, 3.5155vw, 65px);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    .contact-hero-content p {
        font-size: clamp(20px, 1.5625vw, 25px);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    #contact-form-section {
        display: flex;
        justify-content: space-around;
        padding: 10vh 0;
        height: 100vh;
    }

    .contact-form-image {
        width: 40%;
        height: 100%;
    }

    .contact-form-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .form-info {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2vh;
    }

    .form-info h2 {
        font-size: clamp(30px, 2.3438vw, 40px);
    }

    .form-info p {
        font-size: clamp(15px, 1.1719vw, 20px);
    }

    .contact-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
    }

    .contact-form input, .contact-form textarea {
        padding: 1vh 1vw;
        font-size: clamp(15px, 1.1719vw, 20px);
    }

    .contact-form textarea {
        resize: none;
        max-width: 100%;
        min-width: 100%;
        max-height: 45%;
        min-height: 45%;
    }

    .contact-form button {
        padding: 1vh 2vw;
        font-size: clamp(15px, 1.1719vw, 20px);
        width: fit-content;
    }
}

@media only screen and (min-width: 260px) and (max-width: 428px) {
    #contact-hero-section {
        padding: var(--10vh) 0 0 0;
        height: calc(var(--10vh) * 5);
        background-image: url('https://www.theladders.com/wp-content/uploads/handshake_190617.jpg');
        background-size: cover;
        background-position: center;
    }

    .contact-hero-content {
        display: flex;
        flex-direction: column;
        padding: 0 3vw;
        justify-content: center;
        gap: calc(var(--1vh)*2);
        color: white;
        height: 100%;
    }

    .contact-hero-content h1 {
        font-size: clamp(40px, 11.11vw, 50px);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    .contact-hero-content p {
        font-size: 20px;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    }

    #contact-form-section {
        display: flex;
        flex-direction: column;
        gap: calc(var(--1vh) * 2);
        padding: var(--10vh) 0;
    }

    .contact-form-image {
        width: 95%;
        height: 50%;
        margin: 0 auto;
    }

    .contact-form-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .form-info {
        width: 95%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: calc(var(--1vh) * 2);
        margin: 0 auto;
    }

    .form-info h2 {
        font-size: clamp(30px, 11.11vw, 40px);
    }

    .form-info p {
        font-size: 20px;
    }

    .contact-form {
        width: 95%;
        display: flex;
        flex-direction: column;
        gap: calc(var(--1vh) * 3);
        height: 50%;
        margin: 0 auto;
    }

    .contact-form input, .contact-form textarea {
        padding: calc(var(--1vh) * 1) calc(var(--1vh) * 2);
        font-size: 20px;
    }

    .contact-form textarea {
        resize: none;
        max-width: 100%;
        min-width: 100%;
        max-height: calc(var(--1vh) * 30);
        min-height: calc(var(--1vh) * 30);
    }
}