:root {
  --100vh: 720px;
  --10vh: calc(var(--100vh)/10);
  --1vh: calc(var(--100vh)/100);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, p {
  cursor: default;
}

header {
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100vw;
  transition: background-color 0.15s;
}

.nav-scrolled {
  background-color: rgba(255, 255, 255, 0.555);
  backdrop-filter: blur(7px);
}

.nav-scrolled a {
  color: black !important;
}

.nav-desktop {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.nav-links {
  grid-column: 1;
  align-self: center;
  justify-self: start;
  margin: 0 0 0 2vw;
}

.nav-list {
  display: flex;
  gap: 1vw;
}

.nav-item {
  list-style: none;
}

.nav-item a {
  text-decoration: none;
  color: white;
  transition: color 0.15s;
}

.nav-logo {
  grid-column: 2;
  align-self: center;
  justify-self: center;
  height: 100%;
}

.nav-logo img {
  height: 100%;
  width: auto;
}

.nav-btn {
  grid-column: 3;
  align-self: center;
  justify-self: end;
  margin: 0 2vw 0 0;
  cursor: pointer;
}

.btn-primary {
  background-color: #eeba2c;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.15s;
  text-decoration: none;
}

.btn-primary:hover {
  background-color: #f5d358;
}

.btn-secondary {
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.15s;
}

.btn-secondary:hover {
  background-color: #34495e;
}

.hero-section {
  background-color: #f5f5f5;
}

#nav-contact-btn {
  text-decoration: none;
  height: 4vh;
}

.btn-disabled {
  background-color: #bdc3c7 !important;
  color: white !important;
  cursor: not-allowed !important;
}

@media only screen and (min-width: 768px) and (max-width: 1920px) {
  header {
    height: 10vh;
  }

  .nav-desktop {
    grid-template-rows: 10vh;
    height: 10vh;
  }

  .nav-btn {
    font-size: 1vw !important;
  }

  .btn-primary {
    padding: 1vh 2vw;
    font-size: 1.5vw;
  }

  .btn-secondary {
    padding: 1vh 2vw;
    font-size: 1.5vw;
  }

  .hero-section {
    padding: 10vh 0 0 0;
    height: 100vh;
  }
}

@media only screen and (min-width: 260px) and (max-width: 428px) {
  header {
    height: var(--10vh);
  }

  #burger-menu {
    font-size: clamp(50px, 15.2777vw, 60px);
    color: white;
  }

  .nav-scrolled #burger-menu {
    color: black;
  }

  #burger-menu-list {
    position: fixed;
    top: var(--10vh);
    left: 0;
    width: 100vw;
    height: calc(4*var(--10vh));
    background-color: rgba(177, 177, 177, 0.877);
    backdrop-filter: blur(7px);
  }

  #burger-menu-list ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 0 0 0 2vw;
  }

  .burger-menu-link a{
    color: black;
    text-decoration: none;
    font-size: clamp(20px, 6.1111vw, 24px);
  }

  .burger-open {
    animation: burger-menu-in 0.5s;
  }

  .burger-close {
    animation: burger-menu-out 0.5s;
  }

  .nav-desktop {
    grid-template-rows: var(--10vh);
    height: var(--10vh);
  }

  .nav-btn {
    font-size: clamp(15px, 4.16vw, 19px) !important;
    height: calc(var(--1vh)*5) !important;
    padding: calc(var(--1vh)*1.2  ) 2vw 0 2vw !important;
  }

  .btn-primary {
    padding: calc(var(--1vh)*1.5) 2vw;
    font-size: clamp(15px, 4.16vw, 19px) !important;
    width: fit-content;
  }

  .btn-secondary {
    padding: var(--1vh) 2vw;
    font-size: clamp(15px, 4.16vw, 19px) !important;
  }

  .hero-section {
    padding: var(--10vh) 0 0 0;
    height: var(--100vh);
  }

  #nav-contact-btn {
    text-decoration: none;
    padding: 12px 2vw 0 2vw; 
  }
}

@keyframes burger-menu-in {
  from {
    left: 100vw;
  }
  to {
    left: 0;
  }
}

@keyframes burger-menu-out {
  from {
    left: 0;
  }
  to {
    left: 100vw;
  }
}