@media only screen and (min-width: 768px) and (max-width: 1920px) {
    footer {
        height: 50vh;
    }

    .footer-action {
        width: 90%;
        margin: 0 auto;
        height: 80%;
        border-bottom: 1px solid rgb(0, 0, 0);
        display: grid;
        grid-template-columns: 43% repeat(3, 1fr);
        grid-template-rows: 1fr;    
    }

    .footer-newsletter {
        grid-column: 1;
        display: flex;
        flex-direction: column;
        gap: 2vh;
        height: calc(50vh*0.8);
    }

    .footer-logo {
        height: 30%;
    }

    .footer-logo img {
        height: 100%;
        width: auto;
    }

    .footer-newsletter-description {
        font-size: clamp(15px, 1.1719vw, 20px);
    }

    .footer-newsletter-form {
        display: flex;
        gap: 1vw;
    }

    .footer-newsletter-form input {
        width: 70%;
        height: 100%;
        padding: 0 1vw;
        font-size: clamp(15px, 1.1719vw, 20px);
    }

    .footer-newsletter-privacy {
        font-size: clamp(11px, 0.8593vw, 15px);
    }

    .footer-action-links {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-action-links h2 {
        font-size: clamp(20px, 1.5625vw, 30px);
    }

    .footer-action-links a {
        color: black;
        text-decoration: none;
        font-size: clamp(15px, 1.1719vw, 20px);
    }

    #footer-action-primary-links {
        grid-column: 3;
    }

    .footer-information {
        width: 90%;
        margin: 0 auto;
        height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer-information p {
        font-size: clamp(12px, 0.9375vw, 15px);
    }

    .footer-information-links {
        display: flex;
        gap: 1vw;
    }

    .footer-information-links a {
        color: black;
        font-size: clamp(13px, 1.0156vw, 17px);
    }
}

@media only screen and (min-width: 260px) and (max-width: 428px) {
    footer {
        height: calc(var(--1vh)*50);
    }

    .footer-action {
        display: flex;
        flex-direction: column;
        gap: calc(var(--1vh)*2);
        align-items: center;
    }

    .footer-newsletter {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: calc(var(--1vh)*2);
    }

    .footer-logo {
        height: calc(var(--1vh)*15);
        margin: 0 auto 0 0;
    }

    .footer-logo img {
        height: 100%;
        width: auto;
    }

    .footer-newsletter-description {
        font-size: clamp(15px, 1.1719vw, 20px);
    }

    .footer-newsletter-form {
        display: flex;
        flex-direction: column;
        gap: calc(var(--1vh)*1);
        width: 100%;
    }

    .footer-newsletter-form input {
        width: 100%;
        height: calc(var(--1vh)*5);
        padding: 0 1vw;
        font-size: clamp(15px, 1.1719vw, 20px);
    }

    .footer-newsletter-form button {
        width: 100%;
    }

    .footer-newsletter-privacy {
        font-size: clamp(12px, 3.3333vw, 17px);
    }

    .footer-action-links {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: calc(var(--1vh)*2);
    }

    .footer-action-links h2 {
        font-size: clamp(20px, 1.5625vw, 30px);
    }

    .footer-action-links a {
        color: black;
        text-decoration: none;
        font-size: clamp(15px, 1.1719vw, 20px);
    }

    .footer-information {
        height: calc(var(--1vh)*10);
        padding: var(--1vh) 0;
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: calc(var(--1vh)*2);
        align-items: center;
        border-top: 1px solid rgb(0, 0, 0);
        margin: 0 auto;
    }

    .footer-information p {
        font-size: clamp(12px, 0.9375vw, 15px);
    }

    .footer-information-links {
        display: flex;
        gap: calc(var(--1vh)*1);
    }

    .footer-information-links a {
        color: black;
        font-size: clamp(13px, 1.0156vw, 17px);
    }
}